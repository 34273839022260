<template>
  <v-container fluid class="fill-height">
    <v-row class="d-flex flex-row   pa-0 ma-0" justify="end">
      <div>
        <v-select
          v-show="$appConfig.langs.length > 1"
          background-color="primary"
          outlined
          flat
          dense
          :rounded="$appConfig.textbox.rounded"
          dark
          style="width: 180px; max-width:200px; "
          :items="$appConfig.langs"
          item-text="name"
          item-value="id"
          v-model="$i18n.locale"
        >
          <template slot="prepend-inner">
            <v-icon>mdi-earth</v-icon>
          </template>
        </v-select>
      </div>
    </v-row>
            <div class="mssg" v-if="!close" v-html="
                            this.$appConfig.messageClose.find(
                              (_) => _.langId === $i18n.locale
                            ).message
                          ">
            </div>
    <v-row class="fill-height justify-center">
      <v-spacer> </v-spacer>

      <v-col align-self="center" class="justify-center"  v-if="close">
        <v-card class="pa-4" elevation="2" width="400" outlined>
          <v-card-title class="justify-center pa-0 ma-0 pb-4">
            <p
              :class="titleSize"
              v-html="
                this.$appConfig.homeTitle.find(
                  (_) => _.culture === $i18n.locale
                ).name
              "
            ></p>
          </v-card-title>
          <v-form ref="editForm">
            <v-row align="center">
              <v-col md="9" sm="12" cols="12" class="pb-0" align-self="center">
                <v-text-field
                  outlined
                  :rounded="$appConfig.textbox.rounded"
                  clearable
                  :label="$t('home.codeTextBoxLabel')"
                  type="text"
                  v-model="code"
                  :rules="[rules.required]"
                  v-on:keydown.enter.prevent="CheckCode"
                >
                </v-text-field>
              </v-col>
              <v-col md="3" sm="12" cols="12" align-self="center" class="pa-0">
                <v-btn
                  class="mb-0 mb-md-5 ma-0 "
                  :block="$vuetify.breakpoint.smAndDown ? true : false"
                  :fab="fabButton"
                  color="primary"
                  dark
                  :x-large="$vuetify.breakpoint.smAndDown ? true : false"
                  :rounded="
                    $appConfig.hasOwnProperty('button')
                      ? $appConfig.button.rounded
                      : false
                  "
                  @click="CheckCode()"
                >
                  <div :hidden="$vuetify.breakpoint.smAndDown ? false : true">
                    {{ $t("home.findButton") }}
                  </div>
                  <v-icon
                    :hidden="$vuetify.breakpoint.smAndDown ? false : true"
                  >
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <FlashMessage :position="'right top'"></FlashMessage>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-col>
      <v-spacer> </v-spacer>
      <v-spacer> </v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { uuid } from "vue-uuid";

export default {
  name: "Home",
  data() {
    return {
      fingerPrint: "",
      code: "",
      responseToken: "",
      rules: {
        required: (value) => !!value || this.$t("common.required"),
      },
      apiErrorMessage: [],
      loading: false,
      close: true,
      isFirstLoad: true,
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Check if the origin is from the back event
      vm.isFirstLoad = from.name === null;
    });
  },

  mounted() {
    this.GetFingerPrint();
    this.messageClose();
    this.$store.dispatch("updateSelectedProduct", null);
    this.$store.dispatch("updateCatalogToken", "");
    this.$store.dispatch("updateCode", "");
    this.$root.redeemConfirming = true;
    if (this.$route.params.code) {
      this.code = this.$route.params.code;
    }
    //this.$appInsights.trackPageView({ name: "Home" });
    this.$appInsights.context.sessionManager.automaticSession.id = uuid.v1();
    this.fillQueryParamCodeCTCAndCheck();
  },
  
  methods: {
    messageClose() {
      if (this.$appConfig.activeCampaign != null || this.$appConfig.activeCampaign != undefined) {
        this.close = this.$appConfig.activeCampaign
      }

    },
    /*showEmail(){      
      if (this.$appConfig.showEmailDefault != null || this.$appConfig.showEmailDefault != undefined) {
          if (this.$appConfig.showEmailDefault == true) {
            if (this.$appConfig.emailDefault != null || this.$appConfig.emailDefault != undefined) {             
              this.emailConfirm = this.$appConfig.emailDefault;
              this.redeemRequest.Email = this.$appConfig.emailDefault;
            }  
          } 
      },*/
    async GetFingerPrint() {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load();

      (async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise;
        const result = await fp.get();

        // This is the visitor identifier:
        const visitorId = result.visitorId;

        this.fingerPrint = visitorId;
      })();
    },
    async CheckCode() {
      const isValid = this.$refs.editForm.validate();

      if (isValid) {
        this.loading = true;
        await this.$recaptchaLoaded();
        this.$recaptcha("CheckCode").then((token) => {
          // console.log(token);

          axios
            .get(this.apiurl, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": this.$i18n.locale,
                "Campaign-Key": this.$campaignKey,
                "Finger-Print": this.fingerPrint,
                "Recaptcha-Token": token,
                "x-functions-key":
                  process.env.VUE_APP_API_CODEVALIDATION_FUNCTION_KEY,
                "ai-operation-id": this.$appInsights.context.telemetryTrace
                  .traceID,
                "ai-session-id": this.$appInsights.context.sessionManager
                  .automaticSession.id,
              },
            })
            .then((response) => {
              this.$appInsights.trackEvent({
                name: "frontend-code-validation-success",
                properties: {
                  fingerprint: this.fingerPrint,
                  code: this.code.trim(),
                },
              });
              this.responseToken = response.data;
              this.$store.dispatch("updateCatalogToken", this.responseToken);
              this.$store.dispatch("updateCode", this.code.trim());
              this.$router.push("/" + this.$campaignName + "/catalog");
            })
            .catch((error) => {
              if (error.response) {
                this.$appInsights.trackEvent({
                  name: "frontend-code-validation-failure",
                  properties: {
                    fingerprint: this.fingerPrint,
                    code: this.code.trim(),
                    status: error.response.status,
                  },
                });
                switch (error.response.status) {
                  case 409:
                  case 404:
                    error.response.data.forEach((element) => {
                      this.flashMessage.info({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });

                    break;
                  case 423:
                    error.response.data.forEach((element) => {
                      this.flashMessage.warning({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });

                    break;

                  default:
                    error.response.data.forEach((element) => {
                      this.flashMessage.error({
                        title: this.$t("common.cruderror"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });
                    break;
                }
              }
              //console.clear();
            })
            .finally(() => {
              this.loading = false;
            });
        });
      }
    },
    fillQueryParamCodeCTCAndCheck() {
      var self = this;
      if (this.$route.query.codeCTC) {
        this.code = this.$route.query.codeCTC;
        if (this.isFirstLoad) {
          this.$nextTick(() => {
            self.CheckCode();
          });
        }
      }
    },
  },
  computed: {
    apiurl() {
      return (
        process.env.VUE_APP_API_HOST + "/CodeValidation/" + this.code.trim()
      );
    },
    fabButton() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return true;
      }
    },
    titleSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "pa-0 ma-0 text-h5";
        case "sm":
          return "pa-0 ma-0 text-sm-h5";
        case "md":
          return "pa-0 ma-0 text-md-h5";
        case "lg":
          return "pa-0 ma-0 text-lg-h5";
        case "xl":
          return "pa-0 ma-0 text-xl-h5";
        default:
          return "pa-0 ma-0 text-xs-h5";
      }
    },
  },
};
</script>
<style>
#grecaptcha-box {
  width: 260px;
  overflow: hidden;
  position: relative;
  height: 75px;
}
#grecaptcha-box .grecaptcha-badge {
  box-shadow: none !important;
}
</style>
